<template>
  <v-container fluid>
    <p class="text-h5 text-left">연구소 관리</p>
    <div style="text-align: left; border: 1px solid #aaaaaa; padding: 20px">
      <label style="display: inline-block; width: 100px; text-align: right;">연구소명</label>
      <input v-model="param.labName" type="text" style="width:150px; margin: 0 10px" class="blue_input">
      <button type="button" class="pp_s" @click="search">검색</button>
    </div>

    <table class="popup-table mt-6" style="width: 100%">
      <colgroup>
        <col width="10%">
        <col width="40%">
        <col width="50%">
      </colgroup>
      <thead>
      <tr>
        <th class="td_dark_blue">NO.</th>
        <th class="td_dark_blue pointer" @click="setSort('labCode')">연구소코드 <v-icon v-if="checkSort('labCode')" small>{{ checkSort('labCode') }}</v-icon></th>
        <th class="td_dark_blue pointer" @click="setSort('labName')">연구소명 <v-icon v-if="checkSort('labName')" small>{{ checkSort('labName') }}</v-icon></th>
      </tr>
      </thead>
      <thead v-if="loading">
      <tr>
        <th colspan="3" class="td_loading">
          <v-progress-linear
            indeterminate
            color="#0f2585"
          ></v-progress-linear>
        </th>
      </tr>
      </thead>
      <tbody v-if="items.length > 0">
      <template v-for="(item,index) in items">
        <tr :key="index" :class="selectedIndex === index ? 'selected' : ''">
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.rnum }}</td>
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.labCode }}</td>
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.labName }}</td>
        </tr>
      </template>
      </tbody>
      <tbody v-else>
      <tr>
        <td class="no-data" colspan="3">데이터를 찾을 수 없습니다.</td>
      </tr>
      </tbody>

    </table>
    <tech-pagination :page.sync="pageable.page" :rows-per-page="5" :page-size="10" :total-elements="pageable.totalElements"/>

    <table class="popup-table mt-6" style="width: 100%">
      <colgroup>
        <col width="10%">
        <col width="10%">
        <col width="20%">
        <col width="20%">
        <col width="20%">
        <col width="20%">
      </colgroup>
      <thead>
      <tr>
        <th class="td_dark_blue">NO.</th>
        <th class="td_dark_blue pointer" @click="setUserSort('userCode')">사용자코드 <v-icon v-if="checkUserSort('userCode')" small>{{ checkUserSort('userCode') }}</v-icon></th>
        <th class="td_dark_blue pointer" @click="setUserSort('userName')">사용자명 <v-icon v-if="checkUserSort('userName')" small>{{ checkUserSort('userName') }}</v-icon></th>
        <th class="td_dark_blue pointer" @click="setUserSort('socialNumber')">주민등록번호 <v-icon v-if="checkUserSort('socialNumber')" small>{{ checkUserSort('socialNumber') }}</v-icon></th>
        <th class="td_dark_blue pointer" @click="setUserSort('userPart')">소속 <v-icon v-if="checkUserSort('userPart')" small>{{ checkUserSort('userPart') }}</v-icon></th>
        <th class="td_dark_blue pointer" @click="setUserSort('userClass')">신분구분 <v-icon v-if="checkUserSort('userClass')" small>{{ checkUserSort('userClass') }}</v-icon></th>
      </tr>
      </thead>
      <thead v-if="userLoading">
      <tr>
        <th colspan="6" class="td_loading">
          <v-progress-linear
            indeterminate
            color="#0f2585"
          ></v-progress-linear>
        </th>
      </tr>
      </thead>
      <tbody v-if="users.length > 0">
      <template v-for="(item,index) in users">
        <tr :key="index">
          <td class="td_blue cen bo">{{ index + 1 }}</td>
          <td class="td_blue cen bo">{{ item.userCode }}</td>
          <td class="td_blue cen bo">{{ item.userName }}</td>
          <td class="td_blue cen bo">{{ item.formattedSocialNumber }}</td>
          <td class="td_blue cen bo">{{ item.userPart }}</td>
          <td class="td_blue cen bo">{{ item.userClass }}</td>
        </tr>
      </template>
      </tbody>
      <tbody v-else>
      <tr>
        <td class="no-data" colspan="6">데이터를 찾을 수 없습니다.</td>
      </tr>
      </tbody>

    </table>
  </v-container>
</template>

<script>
import TechPagination from '@/components/Pagination'
export default {
  name: 'LabManage',
  components: {TechPagination},
  data() {
    return {
      loading: false,
      userLoading: false,
      param: {
        start: 1,
        end: 5,
        labName: null,
        sort: ''
      },
      pageable: {
        page: 1,
        totalElements: 0
      },
      userParam: {
        labCode: null,
        sort: ''
      },
      items: [],
      users: [],
      selectedIndex: -1
    }
  },
  watch: {
    'pageable.page' () {
      this.searchList()
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    search() {
      if (this.pageable.page === 1) {
        this.searchList()
      } else {
        this.pageable.page = 1
      }
    },
    searchList() {
      this.loading = true

      this.param.start = (this.pageable.page - 1) * 5;
      this.param.end = this.pageable.page * 5

      this.$http.get('/api/sys/lab', {params: this.param})
      .then(({ data }) => {
        this.selectedIndex = -1
        this.items = data
        this.users = []
        this.userParam.labCode = null
        if (data.length > 0) {
          this.pageable.totalElements = data[0].totalElements
        } else {
          this.pageable.totalElements = 0
        }
        this.loading = false
      })
    },
    searchUsers(labCode) {
      this.userLoading = true
      if (labCode) {
        this.userParam.labCode = labCode
      }
      this.$http.get('/api/sys/lab/users', {params: this.userParam})
      .then(({ data }) => {
        this.users = data
        this.userLoading = false
      })
    },
    itemSelect(item, index) {
      this.selectedIndex = index
      this.searchUsers(item.labCode)
    },
    setSort(fieldName) {
      const sort = this.param.sort
      if (sort.indexOf(fieldName) > -1) {
        if (sort.indexOf(',desc') > 0) {
          this.param.sort = ''
        } else {
          this.param.sort = `${fieldName},desc`
        }
      } else {
        this.param.sort = `${fieldName},asc`
      }
      this.search()
    },
    checkSort(fieldName) {
      const sort = this.param.sort
      if (sort.indexOf(fieldName) > -1) {
        if (sort.indexOf(',desc') > 0) {
          return 'mdi-arrow-down'
        } else {
          return 'mdi-arrow-up'
        }
      } else {
        return ''
      }
    },
    setUserSort(fieldName) {
      const sort = this.userParam.sort
      if (sort.indexOf(fieldName) > -1) {
        if (sort.indexOf(',desc') > 0) {
          this.userParam.sort = ''
        } else {
          this.userParam.sort = `${fieldName},desc`
        }
      } else {
        this.userParam.sort = `${fieldName},asc`
      }
      this.searchUsers()
    },
    checkUserSort(fieldName) {
      const sort = this.userParam.sort
      if (sort.indexOf(fieldName) > -1) {
        if (sort.indexOf(',desc') > 0) {
          return 'mdi-arrow-down'
        } else {
          return 'mdi-arrow-up'
        }
      } else {
        return ''
      }
    },
  }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
